
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faXTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

export default function Footer()
{
    return (
        <div className="mt-20 sm:mt-0 mb-10 mx-auto w-4/8 max-w-5xl border-t border-slate-900 text-slate-600">
            <div className="flex justify-between items-center">
                <h1 className="font-bold">Copyright &#169; 2024, All rights reserved Created by <span className="text-slate-400">Elkamal</span></h1>
                <div className="flex">
                    <a target="_blank" rel="noreferrer" href="https://x.com/Muhamma84986027" className="w-10 h-10 flex justify-center items-center hover:text-slate-300"><FontAwesomeIcon icon={faXTwitter} /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mohammed-elkamal-b07797199" className="w-10 h-10 flex justify-center items-center hover:text-slate-300"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mo_elkamel/" className="w-10 h-10 flex justify-center items-center hover:text-slate-300"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a target="_blank" rel="noreferrer" href="https://github.com/moelkama/" className="w-10 h-10 flex justify-center items-center hover:text-slate-300"><FontAwesomeIcon icon={faGithub} /></a>
                </div>
            </div>
        </div>
    )
}