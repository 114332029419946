
import {ReactComponent as C} from '../assets/c.svg'
import {ReactComponent as Cpp} from '../assets/cpp.svg'
import {ReactComponent as Js} from '../assets/js.svg'
import {ReactComponent as Py} from '../assets/py.svg'
import {ReactComponent as Dj} from '../assets/dj.svg'
import {ReactComponent as Html} from '../assets/html.svg'
import {ReactComponent as Css} from '../assets/css.svg'
import {ReactComponent as Tailwind} from '../assets/tailwind.svg'
import {ReactComponent as Reactsvg} from '../assets/react.svg'
import {ReactComponent as GitHub} from '../assets/github.svg'
import {ReactComponent as Git} from '../assets/git.svg'
import {ReactComponent as Linux} from '../assets/linux.svg'
import {ReactComponent as Docker} from '../assets/docker.svg'
import {ReactComponent as Mariadb} from '../assets/mariadb.svg'
import {ReactComponent as Postman} from '../assets/postman.svg'
import {ReactComponent as Node} from '../assets/node.svg'


function Skill({ className, children })
{
    return (
        <div className={className}>
            {children}
        </div>
    );
}
// bg-black
export default function Skills({ REF })
{
    return (
        <div ref={REF} className="-z-10 min-h-screen mt-20 md:mt-0 flex flex-col gap-4 justify-center items-center">
            <h1 className="text-center text-4xl font-black text-slate-300 mb-20">Skills and Tools I Use</h1>
            <div className="z-40 flex gap-4 flex-wrap-reverse justify-center items-center">
                <Skill className={"after:animate-blur-10 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#3940a4] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <C />
                </Skill>
                <Skill className={"after:animate-blur-3 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#00549d] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Cpp />
                </Skill>
                <Skill className={"after:animate-blur-15 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#FFD600] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Js />
                </Skill>
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#0277BD] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Py />
                </Skill>
                <Skill className={"after:animate-blur-15 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#004d40] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Dj />
                </Skill>
            </div>
            <div className="z-40 flex gap-4 flex-wrap-reverse justify-center">
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#e7a42b] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Html />
                </Skill>
                <Skill className={"after:animate-blur-10 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#2aa4f4] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Css />
                </Skill>
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#00c1e0] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Tailwind />
                </Skill>
                <Skill className={"after:animate-blur-15 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#8bb7f0] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Reactsvg />
                </Skill>
                <Skill className={"after:animate-blur-10 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#EEE] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <GitHub />
                </Skill>
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#F4511E] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Git />
                </Skill>
            </div>
            <div className="z-40 flex gap-4 flex-wrap-reverse items-center justify-center">
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#ffffff] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Linux />
                </Skill>
                <Skill className={"after:animate-blur-3 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#007ad9] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Docker />
                </Skill>
                <Skill className={"after:animate-blur-5 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#00a3a6] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Mariadb />
                </Skill>
                <Skill className={"after:animate-blur-10 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#FF6C37] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Postman />
                </Skill>
                <Skill className={"after:animate-blur-15 relative after:rounded-3xl after:content-[''] after:absolute after:h-full after:w-full after:bg-[#388e3c] after:p-4 after:-z-10 inset-x-0 inset-y-0  kkkkkkkkkkk border-skills_border h-20 w-20 rounded-2xl flex justify-center items-center bg-black"}>
                    <Node />
                </Skill>
            </div>
        </div>
    );
}