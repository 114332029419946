import { useRef, useEffect } from 'react';
import Nav from './components/Nav';
import Aboutme from './components/Aboutme';
import Education from './components/Education';
import Projects from './components/Projects';
import Skills from './components/Skills'
import Contact from './components/Contact';
import Footer from './components/Footer';
import emailjs from 'emailjs-com';
import { Analytics } from "@vercel/analytics/react"

// import ReactDOM from "react-dom/client";
// import { ScrollRestoration, Routes, Route } from "react-router-dom";

function add_path_animation(path_ref) {
    let path_lenght = path_ref.current.getTotalLength();

    path_ref.current.style.strokeDasharray = path_lenght + ' ' + path_lenght;
    path_ref.current.style.strokeDashoffset = path_lenght;
    window.addEventListener('scroll', () => {
        let scroll = window.scrollY;
        let height = document.documentElement.scrollHeight - window.innerHeight;
        let progress = scroll / height;
        let draw = path_lenght * progress;
        path_ref.current.style.strokeDashoffset = path_lenght - draw;
    });
}

export default function App() {
    const Aboutme_ref = useRef(null);
    const Education_ref = useRef(null);
    const Projects_ref = useRef(null);
    const Skills_ref = useRef(null);
    const Contact_ref = useRef(null);
    const path1_ref = useRef(null);
    const path2_ref = useRef(null);

    const navigation_buttons = [
        {value: 'About', ref: Aboutme_ref},
        {value: 'Education', ref: Education_ref},
        {value: 'Projects', ref: Projects_ref},
        {value: 'Skills', ref: Skills_ref},
        {value: 'Contact', ref: Contact_ref},
    ];
    useEffect(() => {
            const now = new Date();
            fetch("https://ipinfo.io/json") 
            .then(response => response.json())
            .then(addr => {
                emailjs.send(
                    'service_yz7s7a6',
                    'template_hreckon',
                    {
                        date: `${String(now.getDate()).padStart(2, '0')}-${String(now.getMonth() + 1).padStart(2, '0')}-${now.getFullYear()}_${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`,
                        city: addr.city,
                        ip: addr.ip,
                        loc: addr.loc
                    },
                    'vSlM4dzbfh-MbQPdq' //EmailJS user ID
                  )
            }).catch((error) => {
                console.error(error); // This will log if the promise is rejected
              });
            ////////////////////
            add_path_animation(path1_ref);
            add_path_animation(path2_ref);

            return () => {
                window.removeEventListener('scroll');
            };
        }
        , []);

    return (
        // className='overflow-y-hidden'
        <div className=''>
            <Analytics />
            <div className="fixed -z-50 h-96 w-96 bg-gradient-to-l from-slate-500 to-yellow-500 blur-[150px] rounded-full left-1/4	top-1/4"></div>
            <div className="fixed -z-50 h-96 w-96 bg-gradient-to-l from-slate-500 to-yellow-500 blur-[150px] rounded-full left-3/4 bottom-1/4"></div>
            <div className="h-screen fixed right-1/3">
                <svg className="h-2/3" viewBox="0 0 2531 11553" fill="none" preserveAspectRatio="xMidYMax meet">
                    <path ref={path1_ref} d="M2482 0V8335.97L49 9921.2V11553" stroke="#D1E9E3" stroke-width="97"/>
                </svg>

                <svg className="-z-40 absolute h-1/5" viewBox="0 0 97 3726" fill="none" preserveAspectRatio="xMidYMax meet">
                    <path ref={path2_ref} d="M48.5 0.5C48.5 3133.3 48.5 3786.5 48.5 3721.5" stroke="#D1E9E3" stroke-width="118"/>
                </svg>
            </div>
            <Nav buttons={navigation_buttons} />
            <Aboutme REF={Aboutme_ref} />
            <Education REF={Education_ref} />
            <Projects REF={Projects_ref} />
            <Skills REF={Skills_ref}/>
            <Contact REF={Contact_ref} />
            <Footer />
        </div>
    );
}