
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faXTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function Aboutme({ REF }) {
    return (
        <div ref={REF} className="min-h-screen flex justify-center items-center">
            <div className="mx-4 mt-40 md:mt-0 flex flex-col md:flex-row justify-between items-center gap-20">
                <div className="max-w-96 flex flex-col gap-10">
                    <div className="flex flex-col">
                        <h1 className="text-4xl font-black font-Viga tracking-tight text-slate-50">ELKAMAL Mohammed</h1>
                        <h2 className="ml-2 mt-2 text-lg font-bold font-Viga tracking-tight text-slate-100">Software Developer</h2>
                        <p className="ml-2 mt-8 font-Open text-lg tracking-tight tracking-2 text-slate-100">As a dedicated full-stack developer and software engineer, I specialize in creating intuitive and engaging user experiences. I am passionate about staying current with industry trends and best practices. My experience as a 1337 student has strengthened my ability to engage with emerging technologies, allowing me to continuously improve my skills. I strive to deliver high-quality, user-centered solutions that meet evolving needs.</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                        <div className="flex gap-4 text-xl text-white">
                            <a target="_blank" rel="noreferrer" href="https://x.com/Muhamma84986027" className="hover:border hover:border-slate-500 transition duration-500 ease-in-out w-10 h-10 rounded-xl bg-black hover:bg-transparent flex justify-center items-center"><FontAwesomeIcon icon={faXTwitter} /></a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mohammed-elkamal-b07797199" className="hover:border hover:border-slate-500 transition duration-500 ease-in-out w-10 h-10 hover:bg-transparent bg-blue-700 rounded-xl flex justify-center items-center p-2 "><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mo_elkamel/" className="hover:border hover:border-slate-500 transition duration-500 ease-in-out w-10 h-10 delay-1000 hover:from-transparent hover:to-from-transparent bg-gradient-to-r from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] rounded-xl flex justify-center items-center p-2 "><FontAwesomeIcon icon={faInstagram} /></a>
                            <a target="_blank" rel="noreferrer" href="https://github.com/moelkama/" className="hover:border hover:border-slate-500 transition duration-500 ease-in-out w-10 h-10 hover:text-white hover:bg-transparent text-black bg-white rounded-xl flex justify-center items-center p-2 "><FontAwesomeIcon icon={faGithub} /></a>
                        </div>
                        <a href="/ELKAMAL_MOHAMMED.pdf" target="_blank" rel="noreferrer" className="transition duration-500 ease-in-out text-center text-xl min-h-10 px-4 bg-black hover:text-black text-slate-300 hover:bg-white flex gap-2 borde rounded-xl justify-center items-center">
                            <h1 className=" font-black font-sans">CV</h1>
                            <FontAwesomeIcon className="" icon={faUser} />
                        </a>
                    </div>
                </div>
                <div className="backdrop-sepia bg-white/5 backdrop-blur-lg min-h-[468px] max-w-[440px] rounded-3xl flex flex-col justify-evenly p-6 text-lg font-bold tracking-tight text-slate-300">
                    <div className="">
                        <p className="first-letter:text-2xl first-letter:text-wh mt-2">When I was young, my curiosity always drove me to understand how electronic devices work, and that same curiosity now motivates me to delve deeper into their development, aiming to make them more seamless</p>
                        <p className="first-letter:text-2xl first-letter:text-wh mt-2">My goal now is to enhance my skills as a Software engineers and continue my studies in technologies, particularly in the field of quantum computers, since I am also a physics student.</p>
                        <p className="first-letter:text-2xl first-letter:text-wh mt-2">I love traveling, exploring, playing football, and watching movies and TV series.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}