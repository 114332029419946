
export default function Nav({buttons}) {
    return (
        <nav className="text-slate-300 text-sm font-black z-50 bg-yellow-600f mx-auto max-w-96 fixed inset-x-0 top-2 h-12 rounded-xl backdrop-sepia bg-white/10 backdrop-blur-lg flex justify-evenly items-center">
            {buttons.map((button, index) =>
                <button onClick={(event) => {
                    button.ref.current.scrollIntoView({ behavior: 'smooth' })
                    // document.querySelectorAll('.onactive').forEach((element) => {
                    //     element.classList.remove('text-slate-600');
                    // });
                    // event.target.classList.add('text-slate-600');
                }} className="hover:text-slate-600">{button.value}</button>
                )}
        </nav>
    );
}