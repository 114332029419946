

function    Step({ className, title, description})
{
    return (
        <div className={className}>
            <div className="bg-black rounded-3xl border-slate-400 p-4">
                <h1 className="text-2xl font-bold tracking-tight text-slate-100">{title}</h1>
                <h2 className="mt-2 ml-2 text-xl font-bold tracking-tight text-slate-400">{description}</h2>
            </div>
        </div>
    )
}

export default function Education({ REF }) {
    return (
        // overflow-hidden
        <div ref={REF} className="mt-20 lg:mt-0 lg:h-screen  flex justify-center items-center">
            <div className="h-5/6 lg:h-4/6 w-full xl:w-[1412px] grid grid-rows-6 fffff grid-cols-5 lg:grid-cols-2 lg:gap-x-10">
                <Step className={"left-to-right p-4 flex flex-col justify-center items-center col-start-1 col-end-5 lg:col-end-2 row-start-1 row-end-3"} title={"1337 Common Core"}  description={"I completed my common core training as a software engineer at level 11.09"} />
                <div className="flex items-center col-start-5 lg:col-start-2 row-start-1 row-end-3">
                    <div className="flex justify-center items-center">
                        <div className="bg-slate-200 h-3 w-3 rounded-full"></div>
                        <h1 className="ml-2 text-lg font-bold tracking-tight text-slate-50">2024</h1>
                    </div>
                </div>
                <Step className={"right-to-left p-4 flex flex-col justify-center items-center col-end-6 lg:col-end-2 col-start-2 row-start-3 row-end-5"} title={"Bac +1"} description={"I completed my first year as a student at the Faculty of Sciences, Semlalia Marrakech."} />
                <div className="flex items-center justify-end col-start-1 row-start-3 row-end-5">
                    <div className="flex justify-center items-center">
                        <h1 className="mr-2 text-lg font-bold tracking-tight text-slate-50">2021</h1>
                        <div className="bg-slate-200 h-3 w-3 rounded-full"></div>
                    </div>
                </div>
                <Step className={"left-to-right p-4 flex flex-col justify-center items-center col-end-5 lg:col-end-2 col-start-1 row-start-5 row-end-7"} title={"Bacalaureat"} description={`I successfully obtained my bachelor's degree in physical sciences with "Assez bien".`} />
                <div className="flex items-center col-start-5 lg:col-start-2 row-start-5 row-end-7">
                    <div className="flex justify-center items-center">
                        <div className="bg-slate-200 h-3 w-3 rounded-full"></div>
                        <h1 className="ml-2 text-lg font-bold tracking-tight text-slate-50">2019</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}