
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function Contact({ REF }) {
    const [emailnotvalid, setEmailnotvalid] = useState(false);

    const validateEmail = (email) => {
      // Basic email regex pattern
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      const handleChange = (e) => {
            const { name, value } = e.target;
            if (name === 'email')
                setEmailnotvalid(value === '' || !validateEmail(value));
            setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({ name: '', email: '', subject:'', message: '' });
    
        // 'service_mhj6re6', // Replace with your EmailJS service ID
        // 'template_artzxys', // Replace with your EmailJS template ID
        // 'z7vRv5_2zoGoW8Tao' // Replace with your EmailJS user ID
        emailjs
          .sendForm(
            'service_yz7s7a6',
            'template_epjkkrt',
            e.target, // The form itself
            'vSlM4dzbfh-MbQPdq'
          )
          .then(
            (result) => {
              console.log('Email sent successfully:', result.text);
            },
            (error) => {
              console.error('Error sending email:', error.text);
            }
          ).catch((error) => {
            console.error(error); // This will log if the promise is rejected
          });
      };

    return (
        // bg-gradient-to-l from-slate-500 to-slate-950 
        // 
        <div ref={REF} className="overflow-hidden relative mt-20 md:mt-0 min-h-screen flex flex-col justify-center items-center">
            <div className="z-40 flex flex-col justify-center items-center">
                <h1 className="font-Lobster text-5xl text-center font-bold text-slate-300 mb-10 md:mb-0">Don't hesitate to contact me</h1>
                <div className="flex flex-col lg:flex-row gap-10 justify-center items-center">
                    {/* <img src="/mmm.png" alt="profile" className="h-60 w-60 rounded-full borde border-slate-600 object-cover" /> */}
                    <div className="flex flex-col gap-4 items-center">
                        <form onSubmit={handleSubmit} className="min-h-80 flex flex-col md:flex-row gap-10 items-center justify-center w-full">
                            <div className="flex flex-col gap-4">
                                <TextField name="name" label="name" variant="outlined" required
                                    value={formData.name}
                                    onChange={handleChange}
                                    sx={{
                                        width: '320px',
                                        backgroundColor: '#020617',
                                        "& .MuiOutlinedInput-input": {
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                        },
                                        "& fieldset": {
                                            border: '1px #64748B solid',
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                border: '1px #64748B solid',
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#94A3B8' },
                                    }}
                                    inputProps={{
                                        spellCheck: false, // Disable spell check to block red underline
                                    }}
                                />
                                <TextField name="subject" label="subject" variant="outlined"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    sx={{
                                        width: '320px',
                                        backgroundColor: '#020617',
                                        "& .MuiOutlinedInput-input": {
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                        },
                                        "& fieldset": {
                                            border: '1px #64748B solid',
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                border: '1px #64748B solid',
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#94A3B8' },
                                    }}
                                    inputProps={{
                                        spellCheck: false, // Disable spell check to block red underline
                                    }}
                                />
                                <TextField name="email" label="email" variant="outlined" required  error={emailnotvalid}
                                    value={formData.email}
                                    onChange={handleChange}
                                    sx={{
                                        width: '320px',
                                        backgroundColor: '#020617',
                                        "& .MuiOutlinedInput-input": {
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                        },
                                        "& fieldset": {
                                            border: '1px #64748B solid',
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                border: '1px #64748B solid',
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#94A3B8' },
                                    }}
                                    inputProps={{
                                        spellCheck: false, // Disable spell check to block red underline
                                    }}
                                />
                            </div>
                            <div className="bg-blue-400 relative">
                                <TextField name="message" label="message" variant="outlined" required multiline rows={7}
                                    value={formData.message}
                                    onChange={handleChange}
                                    sx={{
                                        width: '320px',
                                        backgroundColor: '#020617',
                                        "& .MuiOutlinedInput-input": {
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                        },
                                        "& fieldset": {
                                            border: '1px #64748B solid',
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                border: '1px #64748B solid',
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#94A3B8' },
                                    }}
                                    />
                                    <button type="submit" className="bg-slate-300 text-slate-500 absolute bottom-2 right-2 font-bold py-2 px-4 rounded hover:bg-slate-500 hover:text-slate-200">Send</button>
                            </div>
                        </form>
                        <div className="text-slate-100 text-center flex gap-4 flex-col sm:flex-row justify-evenly">
                            <div className="flex gap-4 items-center">
                                <FontAwesomeIcon className='text-xl' icon={faPaperPlane} />
                                <h1 className="text-slate-300 font-bold">elkamalmohammed.dev@gmail.com</h1>
                            </div>
                            <div className="flex gap-4 items-center">
                                <FontAwesomeIcon className='text-xl' icon={faPhone} />
                                <h1 className="text-slate-300 font-bold">+212 613276891</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}